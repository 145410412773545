import Request from '@/utils/request';

//合同列表
export function getContractList(data) {
  return Request('post', '/api/work/contractList', data)
}

//合同详情
export function getContractInfo(contractId) {
  return Request('get', '/apg/finance/contract/newContractEdit?contractId=' + contractId)
}

// 预定合同列表
export function getReserveList(data) {
  return Request('post', '/api/work/preorderList', data)
}

// 预定合同详情
export function getReserveInfo(preorderId) {
  return Request('get', '/api/work/preorderInfo/' + preorderId)
}

//退租回显详情
export function getContractExitInfo(params) {
  return Request('get', '/apg/finance/contract/newContractTerminate?contractId=' + params.contractId + "&date=" + params.date)
}

//退租提交
export function getContractExitSave(data) {
  return Request('post', '/apg/finance/contract/newContractTerminate/newCreate', data)
}

//退租图片回显
export function getContractExitImg(contractId) {
  return Request('get', '/apg/finance/contract/downLoad/additionalAndTerminate?contractAttachType=4&contractId=' + contractId)
}

//退租图片保存
export function getContractExitImgSave(data) {
  return Request('post', '/apg/contract/createTerminateFile', data)
}

//合同数量
export function getContractHomeList(data) {
  return Request('post', '/apg/workBench/popout/popoutList', data)
}